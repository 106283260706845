.carousel {
  position: relative;
  overflow: hidden;
  font-size: 2.5vmin;
}

.carousel * {
  box-sizing: border-box;
}

.carousel-slider * {
  box-sizing: border-box;
}

.carousel-slider {
  display: flex;
  transition: 0.3s;
}

.carousel-slide {
  flex: 1 0 100%;
}

.carousel button {
  font-size: inherit;
}

.carousel-prev,
.carousel-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 1em;
  border: none;
  cursor: pointer;
}

.carousel-prev {
  left: 2em;
}

.carousel-next {
  right: 2em;
}

.carousel-status {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  font-size: 10px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
  color: #fff;
  z-index: 9;
}

.control-dots {
  position: absolute;
  bottom: 0;
  margin: 10px 0;
  padding: 0;
  text-align: center;
  width: 100%;
  z-index: 1;
}

.dot {
  transition: opacity 0.25s ease-in;
  opacity: 0.3;
  filter: alpha(opacity=30);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
  z-index: 9;
}

.dot.selected {
  opacity: 1;
}

/* .featured-item {
    width: 25%;
    display: flex;
  } */

.featured-slider {
  width: 25%;
}

.carousel .featured-slider img {
  width: auto !important;
}

.tab-wrapper {
  position: relative;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  white-space: nowrap;
  scrollbar-width: none;
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 30px;
  text-align: center;
}

.tab-wrapper div {
  display: inline-flex;
}

.\!h-\[348px\] {
  height: 348px !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 4rem;
  height: 28px;
  margin-left: -3px;
  padding: 0px;
  border-radius: 4px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #475569;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 4px;
}

.slider:before {
  content: "'Yes'";
  position: absolute;
  content: '';
  height: 24px;
  width: 24px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  border-radius: 4px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #159e48;
}

input:focus + .slider {
  box-shadow: 0 0 1px #159e48;
}

input:checked + .slider:before {
  -webkit-transform: translateX(calc(4rem - 28px));
  -ms-transform: translateX(calc(4rem - 28px));
  transform: translateX(calc(4rem - 28px));
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.track-content:after,
.track-content:before {
  top: 6px;
  position: absolute;
  color: #fff;
  font-size: 0.7rem;
}

.track-content:after {
  content: 'Yes';
  left: 10px;
}

.track-content:before {
  content: 'No';
  right: 10px;
}

.pl-\[15px\] {
  padding-left: 15px;
}

.pr-\[15px\] {
  padding-right: 15px;
}

.active-accordion {
  margin: 0 0 20px 0;
}

.active-accordion-icon {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

@media (max-width: 1025px) {
  .featured-slider {
    width: 33.33%;
  }
}

@media (max-width: 768px) {
  .featured-slider {
    width: 50%;
  }
}

@media (max-width: 480px) {
  .featured-slider {
    width: 100%;
  }
}

.\!lg\:w-\[100\%\] {
  width: 100% !important;
}