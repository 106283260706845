.sidebar .MuiPaper-root {
  box-shadow: unset;
  margin-bottom: 0 !important;
}
.sidebar .MuiPaper-root:before {
  display: none;
}
.sidebar .MuiTypography-root {
  font-weight: 500;
  color: #000;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
}
.sidebar .sidebarTextInput > * {
  font-size: 12.25px;
}
.sidebar .sidebarTextInput input {
  color: #4b5563;
}
.sidebar .MuiAccordionDetails-root {
  padding: 0px;
}
.sidebar_wrapper {
  padding: 1rem 0;
}
.sidebar .sidebar_title {
  min-height: unset;
}
.sidebar .MuiAccordionSummary-content {
  margin: 0;
}
.sidebar .MuiAccordionSummary-root.Mui-expanded,
.sidebar .MuiAccordionSummary-root {
  min-height: unset;
  padding: 0;
}
.sidebar .MuiAccordionSummary-content.Mui-expanded {
  margin: 0;
}
/* product page */
#gridview [type='checkbox']:focus {
  --tw-ring-inset: #fff;
}
.btn-secondary-hover {
  background-color: #f18a00 !important;
  color: #000000 !important;
}
/* featured_items */
.featured_items .tab-container {
  min-height: 30px;
}
.featured_items .featured_title {
  min-height: 30px;
  min-width: unset;
  line-height: 1;
}

.featured_items .featured_title {
  min-height: 30px;
  min-width: unset;
  line-height: 1;
  color: rgba(0, 58, 112, var(--tw-text-opacity));
  text-transform: capitalize;
  font-weight: 500;
}
.featured_items .featured_title:hover,
.featured_items .Mui-selected {
  --tw-text-opacity: 1;
}

.featured_items .MuiTabs-indicator {
  height: 1px;
}

/* corporate gear banner css */
.banner_details {
  font-family: 'Outfit', sans-serif;
}

.banner_details h1 {
  font-weight: 600;
  margin-bottom: 0.25rem;
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.banner_details h2 {
  font-weight: 400;
  margin-bottom: 0.25rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.banner_details p {
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: -0.01em;
}

@media (min-width: 1024px) {
  .banner_details h1 {
    font-size: 2.25rem;
    line-height: 2.5rem;
    letter-spacing: -0.01em;
  }
  .banner_details h2 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    letter-spacing: -0.01em;
  }
}

@media (min-width: 768px) {
  .banner_details h1 {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  .banner_details h2 {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (max-width: 768px) {
  .banner_section_col {
    flex-direction: column;
  }
}

/* layout3 sidebar */
.layout3_sidebar .MuiPaper-root {
  margin-bottom: 16px !important;
}

@media (min-width: 1280px) {
  .xl\:left-\[24\%\] {
    left: 23%;
  }
}
.xl\:left-\[20\%\] {
  left: 20%;
}
