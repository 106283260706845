.loader-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #0b0b0b73;
  display: grid;
  place-items: center;
  z-index: 9999;
}

.loader {
  border: 6px solid #e3e3e3;
  border-top: 6px solid #ffa400;
  border-bottom: 6px solid #ffa400;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#MainHeader {
  position: sticky;
  top: 0;
}
@media print {
  #MainHeader,
  #MainFooter {
    display: none;
  }
}

.apperance::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* checkbox design issue  */
.input_checkbox {
  position: relative;
  border: 1px solid;
  height: 20px;
  width: 20px;
}

.checkbox {
  top: 2px;
  right: 2px;
  height: 14px;
  width: 14px;
  position: absolute;
}

.checkbox:not(:checked) {
  opacity: 0;
}

.checkbox:checked {
  appearance: none;
  opacity: 1;
  background-color: black;
}

@media print {
  body {
    transform: scale(0.8);
    zoom: 80%;
    margin: 0;
  }
}
